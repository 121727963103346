<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">企业内训</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">我的试题</a>
          <i>></i>
          <a href="javascript:;"  class="cur-a"
            >新建试卷</a
          >
        </span>
      </div>
      <div class="framePage-body addtestPaper">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-form
              ref="ruleForm"
              :model="ruleForm"
              :rules="rules"
              label-width="12rem"
              class="commodity-form"
            >
              <el-form-item label="试卷名称" prop="paperName" class="form-item">
                <el-input
                  size="small"
                  maxlength="50"
                  :show-word-limit="true"
                  v-model="ruleForm.paperName"
                  placeholder="请输入试卷名称"
                />
              </el-form-item>
              <el-form-item
                label="培训类型"
                prop="trainTypeId"
                class="form-item"
              >
                <el-cascader
                  v-model="ruleForm.trainTypeId"
                  :options="TrainTypeList"
                  filterable
                  :show-all-levels="false"
                  :props="{
                    emitPath: false,
                    value: 'id',
                    label: 'label',
                    children: 'children',
                    checkStrictly: true,
                  }"
                  size="small"
                ></el-cascader>
              </el-form-item>
              <el-form-item
                label="通过分数"
                prop="paperScore"
                class="form-item"
              >
                <el-input-number
                  size="small"
                  v-model="ruleForm.paperScore"
                  :min="1"
                  :max="200"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="下载模板" class="form-item">
                <el-button @click="downloadExcel">下载</el-button>
              </el-form-item>
              <el-form-item label="上传试题" prop="fileName">
                <el-upload
                  v-model="ruleForm.fileName"
                  v-if="!ruleForm.fileName"
                  class="upload-demo upload-btn"
                  :action="actionUrl"
                  :on-error="handleError"
                  :on-success="handleSuccess"
                  :on-change="uploadChange"
                  :show-file-list="false"
                  :auto-upload="false"
                >
                  <el-button size="small" class="bgc-bv">选取试题</el-button>
                </el-upload>
                <span style="color: #f46173" v-else>
                  {{ ruleForm.fileName }}
                  <a @click="removeExl" style="color: #5bb5ff">删除</a>
                </span>
              </el-form-item>
              <el-form-item label=" " class="form-item form-item-hint">
                <p>
                  只能上传.XLS 和 .XLSX格式的单个文件，且文件大小不超过1MB！
                </p>
              </el-form-item>
              <div class="form-btn">
                <el-button class="bgc-bv" @click="formCancel">返回</el-button>
                <el-button class="bgc-bv" @click="formSave('ruleForm')"
                  >保存</el-button
                >
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CustomPrograms/testPaperAdd",
  components: {},
  data() {
    return {
      ruleForm: {
        paperName: "", //试卷名称
        paperScore: 60, //通过分数
        trainTypeId: "", //培训类型
        excelUrl: "", //文件key
        salesState: 10,
        fileName: "", //文件名称
      },
      TrainTypeList: [],//培训类型数据
      rules: {
        paperName: [
          { required: true, message: "请输入试卷名称", trigger: "blur" },
        ],
        paperScore: [
          { required: true, message: "请输入通过分数", trigger: "blur" },
        ],
        trainTypeId: [
          { required: true, message: "请选择培训类型", trigger: "change" },
        ],
        fileName: [{ required: true, message: "请上传试卷", trigger: "blur" }],
      },
    };
  },
  computed: {},
   created() {
    this.getTrainTypeArrData();
  },
  methods: {
    //获取培训类型数据
    getTrainTypeArrData() {
      this.$post("/sys/category/train-type/enterprise", {}).then((ret) => {
        this.TrainTypeList = ret.data;
      });
    },
    // 保存
    formSave(formName = "ruleForm") {
      const el = this.$refs[formName];
      el.validate((valid) => {
        if (valid) {
          this.doAjax();
        } else {
          this.$message({
            type: "warning",
            message: "请输入必填项",
          });
        }
      });
    },
    //下载模版
    downloadExcel() {
      /**
       * 下载模板
       * @param param 参数
       */
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = "/static/paper.xls";
      link.setAttribute("download", "paper.xls");
      document.body.appendChild(link);
      link.click();
    },
    doAjax() {
      let params = {
        paperName: this.ruleForm.paperName,
        paperScore: this.ruleForm.paperScore,
        excelUrl: this.ruleForm.excelUrl,
        salesState: this.ruleForm.salesState,
        fileName: this.ruleForm.fileName,
        trainTypeId: this.ruleForm.trainTypeId,
        paperOrgin: 20, //强行设置待审核，未来根据审核功能调整
      };
      if (this.ruleForm.excelUrl == "") {
        this.$message.warning("请先上传试题");
        return false;
      }
      this.$post("/biz/paper/insertPaperQuestion", params)
        .then(() => {
          setTimeout(() => {
            this.$message({
              type: "success",
              message: "保存成功",
            });
            this.formCancel("1");
          }, 300);
        })
        .catch((err) => {
          return;
        });
    },
    formCancel(isRefresh) {
      this.$router.push({
        path: "/web/CustomPrograms/testPaperList",
        query: {
          refresh: isRefresh == "1" ? true : false,
          refName: "first",
          isAdd: isRefresh == "1" ? true : false,
        },
      });
    },
    //上传试题文件
    uploadChange(file) {
      let size = file.size / 1024 / 1024;
      // let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "XLSX";
      const isxlsx = extension === "xlsx";
      const isXLS = extension === "XLS";
      const isxls = extension === "xls";
      if (!isXLSX && !isxlsx && !isXLS && !isxls) {
        this.$message.error("只能上传后缀是.xlsx或者.xls的文件");
        return;
      }
      if (size > 1) {
        this.$message.error("文件大小不能超过1M");
        return;
      }
      this.ruleForm.fileName = file.name;
      let formData = new FormData();
      formData.append("folder ", "QUESTION");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.ruleForm.excelUrl = result.data.fileKey;
          this.$refs["ruleForm"].clearValidate("fileName");
        })
        .catch(() => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "保存失败",
            });
          }, 300);
        });
      this.$forceUpdate();
    },
    //移除文件
    removeExl() {
      this.ruleForm.fileName = "";
      this.ruleForm.excelUrl = "";
      this.$forceUpdate();
    },
  },
};
</script>
<style lang="less" scoped>
.upload-btn {
  height: 40px;
  /deep/.el-upload {
    height: 40px !important;
    border: none !important;
  }
}
.form-btn {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
